var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-overview"},[(
      typeof _vm.getActiveUserData.user !== 'undefined' &&
        Object.keys(_vm.getActiveUserData.user).length
    )?_c('div',[(
        _vm.getActiveUserData.userRoles == 'admin' ||
        _vm.getActiveUserData.userRoles == 'subadmin' ||
          _vm.getActiveUserData.userRoles == 'salesmanager' ||
          _vm.getActiveUserData.userRoles == 'salesteamlead' ||
          _vm.getActiveUserData.userRoles == 'salesperson' ||
          _vm.getActiveUserData.userRoles == 'marketingmanager'
      )?_c('adminvue-projects-settings-vue'):_c('div',[_c('p',[_vm._v("You dont have permision to access this page.")])])],1):_c('div',{staticClass:"white-row loader-route"},[_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])}]

export { render, staticRenderFns }